@use '@angular/material' as mat;
@include mat.core();

// This angular palette theme applies the primary color '#0a4ace'(color--blue) to all angular material components
$nv-palette: (
    50 : #e0edf6,
    100 : #b3d3e9,
    200 : #80b6db,
    300 : #4d98cd,
    400 : #2682c2,
    500 : #0a4ace,
    600 : #0064b0,
    700 : #0059a7,
    800 : #004f9f,
    900 : #003d90,
    A100 : #bcd3ff,
    A200 : #89b1ff,
    A400 : #5690ff,
    A700 : #3c7fff,
    contrast: (
      50 : #000000de,
      100 : #000000de,
      200 : #000000de,
      300 : #000000de,
      400 : #ffffff,
      500 : #ffffff,
      600 : #ffffff,
      700 : #ffffff,
      800 : #ffffff,
      900 : #ffffff,
      A100 : #000000de,
      A200 : #000000de,
      A400 : #000000de,
      A700 : #ffffff,
    )
);

$nv-primary: mat.define-palette($nv-palette, 500);
// Add font family to the default typography config
$default-config: mat.define-typography-config(
  $font-family: var(--font-family-normal),
);
/**
  * Each of the following custom level for angular material components sets all typography rules to the default except for $line-height and $letter-spacing:
  *
  * '$line-height: normal' and '$letter-spacing: normal' was the default prior to angular material 15. By creating the custom levels bellow, they are reset to normal
*/

// Custom headline-1
$headline-1-level: mat.define-typography-level(
  $font-family: mat.font-family($default-config, 'headline-1'),
  $font-weight: mat.font-weight($default-config, 'headline-1'),
  $font-size: mat.font-size($default-config, 'headline-1'),
  $line-height: normal,
  $letter-spacing: normal,
);

// Custom headline-2 level
$headline-2-level: mat.define-typography-level(
  $font-family: mat.font-family($default-config, 'headline-2'),
  $font-weight: mat.font-weight($default-config, 'headline-2'),
  $font-size: mat.font-size($default-config, 'headline-2'),
  $line-height: normal,
  $letter-spacing: normal,
);

// Custom headline-3 level
$headline-3-level: mat.define-typography-level(
  $font-family: mat.font-family($default-config, 'headline-3'),
  $font-weight: mat.font-weight($default-config, 'headline-3'),
  $font-size: mat.font-size($default-config, 'headline-3'),
  $line-height: normal,
  $letter-spacing: normal,
);

// Custom headline-4 level
$headline-4-level: mat.define-typography-level(
  $font-family: mat.font-family($default-config, 'headline-4'),
  $font-weight: mat.font-weight($default-config, 'headline-4'),
  $font-size: mat.font-size($default-config, 'headline-4'),
  $line-height: normal,
  $letter-spacing: normal,
);

// Custom headline-5 level
$headline-5-level: mat.define-typography-level(
  $font-family: mat.font-family($default-config, 'headline-5'),
  $font-weight: mat.font-weight($default-config, 'headline-5'),
  $font-size: mat.font-size($default-config, 'headline-5'),
  $line-height: normal,
  $letter-spacing: normal,
);

// Custom headline-5 level
$headline-5-level: mat.define-typography-level(
  $font-family: mat.font-family($default-config, 'headline-5'),
  $font-weight: mat.font-weight($default-config, 'headline-5'),
  $font-size: mat.font-size($default-config, 'headline-5'),
  $line-height: normal,
  $letter-spacing: normal,
);

// Custom headline-6 level
$headline-6-level: mat.define-typography-level(
  $font-family: mat.font-family($default-config, 'headline-6'),
  $font-weight: mat.font-weight($default-config, 'headline-6'),
  $font-size: mat.font-size($default-config, 'headline-6'),
  $line-height: normal,
  $letter-spacing: normal,
);

// Custom subtitle-1 level
$subtitle-1-level: mat.define-typography-level(
  $font-family: mat.font-family($default-config, 'subtitle-1'),
  $font-weight: mat.font-weight($default-config, 'subtitle-1'),
  $font-size: mat.font-size($default-config, 'subtitle-1'),
  $line-height: normal,
  $letter-spacing: normal,
);

// Custom subtitle-2 level
$subtitle-2-level: mat.define-typography-level(
  $font-family: mat.font-family($default-config, 'subtitle-2'),
  $font-weight: mat.font-weight($default-config, 'subtitle-2'),
  $font-size: mat.font-size($default-config, 'subtitle-2'),
  $line-height: normal,
  $letter-spacing: normal,
);

// Custom body-1 level
$body-1-level: mat.define-typography-level(
  $font-family: mat.font-family($default-config, 'body-1'),
  $font-weight: mat.font-weight($default-config, 'body-1'),
  $font-size: mat.font-size($default-config, 'body-1'),
  $line-height: normal,
  $letter-spacing: normal,
);

// Custom body-2 level
$body-2-level: mat.define-typography-level(
  $font-family: mat.font-family($default-config, 'body-2'),
  $font-weight: mat.font-weight($default-config, 'body-2'),
  $font-size: mat.font-size($default-config, 'body-2'),
  $line-height: normal,
  $letter-spacing: normal,
);

// Custom caption level
$caption-level: mat.define-typography-level(
  $font-family: mat.font-family($default-config, 'caption'),
  $font-weight: mat.font-weight($default-config, 'caption'),
  $font-size: mat.font-size($default-config, 'caption'),
  $line-height: normal,
  $letter-spacing: normal,
);

// Custom button level
$button-level: mat.define-typography-level(
  $font-family: mat.font-family($default-config, 'button'),
  $font-weight: mat.font-weight($default-config, 'button'),
  $font-size: mat.font-size($default-config, 'button'),
  $line-height: normal,
  $letter-spacing: normal,
);

// Define the typography config based on the levels defined above
// TODO - redefine based on the new typography.scss
$my-custom-typography: mat.define-typography-config(
  $headline-1: $headline-1-level,
  $headline-2: $headline-2-level,
  $headline-3: $headline-3-level,
  $headline-4: $headline-4-level,
  $headline-5: $headline-5-level,
  $headline-6: $headline-6-level,
  $subtitle-1: $subtitle-1-level,
  $subtitle-2: $subtitle-2-level,
  $body-1: $body-1-level,
  $body-2: $body-2-level,
  $caption: $caption-level,
  $button: $button-level,
);

$nv-theme: mat.define-light-theme((
 color: (
   primary: $nv-primary,
   accent: $nv-primary,
 ),
 typography: $my-custom-typography,
));

// include theme to all material components
@include mat.all-component-themes($nv-theme);