/**
  * Global Typography mixin utilities
  * contains mixin utilities for typography
  * 
*/

@mixin get-nv-font-shared-rules () {
  text-transform: none;
}

@mixin get-nv-font-color ($color: null) {
  @if $color == null {
    color: var(--color-grey-dark-9);
  } @else {
    color: var(--color-#{$color});
  }
}

@mixin get-nv-font-face ($weightName: null, $fontStyle: null) {
  $parsedFontStyle: null;
  @if $fontStyle == null {
    $parsedFontStyle: normal;
  } @else {
    $parsedFontStyle: $fontStyle;
  }

  font-family: var(--font-family-normal);
  font-style: $parsedFontStyle;
  font-weight: 400;
  
  @if $weightName == 'regular' {
    font-family: var(--font-family-normal);
    font-weight: 400;
  }
  @if $weightName == 'medium' and $parsedFontStyle == 'normal' {
    font-family: 'NvLatoMedium';
    font-weight: 500;
  }
  @if $weightName == 'medium' and $parsedFontStyle == 'italic' {
    font-family: 'NvLatoMediumItalic';
    font-weight: 500;
  }
  @if $weightName == 'semibold' and $parsedFontStyle == 'normal' {
    font-family: 'NvLatoSemibold';
    font-weight: 600;
  }
  @if $weightName == 'semibold' and $parsedFontStyle == 'italic' {
    font-family: 'NvLatoSemiboldItalic';
    font-weight: 600;
  }
  @if $weightName == 'bold' {
    font-family: var(--font-family-normal);
    font-weight: 700;
  }
  @if $weightName == 'black' {
    font-family: var(--font-family-normal);
    font-weight: 900;
  }
}

/**
  * Global Typography mixin
  * mixin can be provided with two optional args
  * 
  * arg `$color` - color of the text, use color variable, default is grey-dark-9
  * arg `$fontStyle` - font style of the text, either normal or italic, default is normal
  *
  * Updates the following properties:
  * - font-size, letter-spacing, line-height
  * - font-family, font-style, font-weight
  * - text-transform
  * - font-color
  * 
  * Usage example:
  * .a-button-class {
  *   @include nv-font-button('blue', italic);
  * }
  * 
  * .a-caption-class {
  *   @include nv-font-caption-1('grey-dark-9');
  * }
  * 
  * .a-body-class {
  *   @include nv-font-body-2(null, italic);
  * }
  * 
  * .a-title-class {
  *   @include nv-font-header-1();
  * }
  * 
*/

@mixin nv-font-header-1($color: null, $fontStyle: null) {
  font-size: 24px;
  letter-spacing: 0.0288em; // 2.88%;
  line-height: 145%;
  @include get-nv-font-face('bold', $fontStyle);
  @include get-nv-font-shared-rules();
  @include get-nv-font-color($color);
}

@mixin nv-font-header-2($color: null, $fontStyle: null) {
  font-size: 20px;
  letter-spacing: 0.024em; // 2.4%;
  line-height: 145%;
  @include get-nv-font-face('bold', $fontStyle);
  @include get-nv-font-shared-rules();
  @include get-nv-font-color($color);
}

@mixin nv-font-body-1($color: null, $fontStyle: null) {
  font-size: 16px;
  letter-spacing: 0.0192em; // 1.92%;
  line-height: 145%;
  @include get-nv-font-face('regular', $fontStyle);
  @include get-nv-font-shared-rules();
  @include get-nv-font-color($color);
}

@mixin nv-font-body-2($color: null, $fontStyle: null) {
  font-size: 14px;
  letter-spacing: 0.0168em; // 1.68%;
  line-height: 145%;
  @include get-nv-font-face('regular', $fontStyle);
  @include get-nv-font-shared-rules();
  @include get-nv-font-color($color);
}

@mixin nv-font-body-3($color: null, $fontStyle: null) {
  font-size: 12px;
  letter-spacing: 0.0156em; // 1.56%;
  line-height: 145%;
  @include get-nv-font-face('regular', $fontStyle);
  @include get-nv-font-shared-rules();
  @include get-nv-font-color($color);
}

@mixin nv-font-subtitle-1($color: null, $fontStyle: null) {
  font-size: 16px;
  letter-spacing: 0.0192em; // 1.92%;
  line-height: 145%;
  @include get-nv-font-face('semibold', $fontStyle);
  @include get-nv-font-shared-rules();
  @include get-nv-font-color($color);
}

@mixin nv-font-subtitle-2($color: null, $fontStyle: null) {
  font-size: 14px;
  letter-spacing: 0.0168em; // 1.68%;
  line-height: 145%;
  @include get-nv-font-face('semibold', $fontStyle);
  @include get-nv-font-shared-rules();
  @include get-nv-font-color($color);
}

@mixin nv-font-subtitle-3($color: null, $fontStyle: null) {
  font-size: 12px;
  letter-spacing: 0.0156em; // 1.56%;
  line-height: 145%;
  @include get-nv-font-face('semibold', $fontStyle);
  @include get-nv-font-shared-rules();
  @include get-nv-font-color($color);
}

@mixin nv-font-button-large($color: null, $fontStyle: null) {
  font-size: 16px;
  letter-spacing: 0.0192em; // 1.92%;
  line-height: 100%;
  @include get-nv-font-face('bold', $fontStyle);
  @include get-nv-font-shared-rules();
  @include get-nv-font-color($color);
}

@mixin nv-font-button-small($color: null, $fontStyle: null) {
  font-size: 14px;
  letter-spacing: 0.0168em; // 1.68%;
  line-height: 100%;
  @include get-nv-font-face('bold', $fontStyle);
  @include get-nv-font-shared-rules();
  @include get-nv-font-color($color);
}

@mixin nv-font-caption-1($color: null, $fontStyle: null) {
  font-size: 10px;
  letter-spacing:0.0144em; // 1.44%;
  line-height: 100%;
  @include get-nv-font-face('bold', $fontStyle);
  @include get-nv-font-shared-rules();
  @include get-nv-font-color($color);
}

@mixin nv-font-caption-2($color: null, $fontStyle: null) {
  font-size: 10px;
  letter-spacing:0.0144em; // 1.44%;
  line-height: 100%;
  @include get-nv-font-face('regular', $fontStyle);
  @include get-nv-font-shared-rules();
  @include get-nv-font-color($color);
}

@mixin nv-font-display($color: null, $fontStyle: null) {
  font-size: 32px;
  letter-spacing: 0.0320em; // 3.20%;
  line-height: 100%;
  @include get-nv-font-face('bold', $fontStyle);
  @include get-nv-font-shared-rules();
  @include get-nv-font-color($color);
}
