/* You can add global styles to this file, and also import other style files */
/* TODO: change import path. Importing from source code of school portal is not allowed in family-portal
  This was only allowed for the first cut since most components and styles are subject to change */

// scool-portal import. Remove this line and import from the correct path
@import '../../shared/nvps-libraries/design/styles/nv-global.scss';

body {
  background-color: var(--color-app-default-bg);
  margin: 0 auto;
}