/*=============================================
=            SCROLL BAR            =
=============================================*/
::-webkit-scrollbar {
  width: var(--scroll-bar);
  height: var(--scroll-bar);
}

::-webkit-scrollbar-thumb {
  background: var(--scroll-bar-color);
  border-radius: var(--space-s);
  border: var(--space-xxs) solid white;
}

::-webkit-scrollbar-corner {
  background: transparent;
}
