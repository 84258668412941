@use './../nv-typography/nv-typography.mixin.scss' as *;

@mixin nv-mat-radio-button {
  &.mat-mdc-radio-button {
    --mdc-radio-disabled-selected-icon-color: var(--color-blue);
    --mdc-radio-disabled-unselected-icon-color: var(--color-grey-dark-8);
    --mdc-radio-unselected-focus-icon-color: var(--color-grey-dark-8);
    --mdc-radio-unselected-hover-icon-color: var(--color-grey-dark-8);
    --mdc-radio-unselected-icon-color: var(--color-grey-dark-8);
    --mdc-radio-unselected-pressed-icon-color: var(--color-grey-dark-8);
    --mdc-radio-selected-focus-icon-color: var(--color-blue);
    --mdc-radio-selected-hover-icon-color: var(--color-blue);
    --mdc-radio-selected-icon-color: var(--color-blue);
    --mdc-radio-selected-pressed-icon-color: var(--color-blue);
    --mat-mdc-radio-checked-ripple-color: unset;
    --mat-mdc-radio-ripple-color: unset;
    --mdc-radio-disabled-unselected-icon-opacity: var(--color-opacity-30);
    --mdc-radio-disabled-selected-icon-opacity: var(--color-opacity-30);
    .mdc-form-field {
      label {
        @include nv-font-body-3;
        padding-left: 0;
        cursor: pointer;
        padding-left: var(--space-s);
      }

      .mdc-radio--disabled + label {
        color: var(--color-primary-text-disabled);
      }
       // prevents radio button in ag-grid from moving out of place
       .mat-mdc-radio-touch-target {
        transform: unset;
      }
    }

    .mat-radio-ripple {
      .mat-ripple-element {
        background-color: unset;
      }
    }

    .mdc-radio {
      padding: unset;
      width: var(--space-m);
      height: var(--space-m);
      .mdc-radio__background {
        width: var(--space-m);
        height: var(--space-m);
        .mdc-radio__inner-circle {
          border-width: var(--space-s)
        }
      }
      // removes the ripple effect on radio button
      .mdc-radio__background::before {
        opacity: 0;
      }
      // removes the ripple effect on radio button
      &:hover .mdc-radio__native-control:not([disabled]):not(:focus) {
        & ~ .mdc-radio__background::before {
          opacity: 0;
        }
      }
    }
  }
}

/*
  component:
  `
    <mat-radio-group class="nv-mat-radio-group>
      <mat-radio-button class="nv-mat-radio-button"></mat-radio-button>
      <mat-radio-button class="nv-mat-radio-button"></mat-radio-button>
      ...
    </mat-radio-group>
  `
*/
.nv-mat-radio-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  mat-radio-button,
  mat-radio-button.nv-mat-radio-button {
    @include nv-mat-radio-button();
    margin-bottom: var(--space-s);
  }
}

mat-radio-button, mat-radio-button.nv-mat-radio-button {
  @include nv-mat-radio-button();
}