@import '../utilities/color.scss';

.mat-ripple {
  .mat-ripple-element {
    background-color: var(--color-blue);
    opacity: 0.1;
  }

  &.mat-ripple-inverted {
    &.mat-ripple-element,
    .mat-ripple-element {
      background-color: var(--color-white);
      opacity: 0.1;
    }
  }
}

@mixin color-ripple($color) {
  &.mat-ripple,
  .mat-ripple {
    .mat-ripple-element {
      background-color: color($color);
      opacity: 0.1;
    }
  }
  &.mat-ripple-inverted {
    &.mat-ripple-element,
    .mat-ripple-element {
      background-color: var(--color-white);
      opacity: 0.2;
    }
  }
}

*.red {
  @include color-ripple('red');
}
*.salmon {
  @include color-ripple('salmon');
}
*.pink {
  @include color-ripple('pink');
}
*.yellow {
  @include color-ripple('yellow');
}
*.green {
  @include color-ripple('green');
}
*.teal {
  @include color-ripple('teal');
}
*.navy {
  @include color-ripple('navy');
}
*.purple {
  @include color-ripple('purple');
}
*.blue {
  @include color-ripple('blue');
}
*.black {
  @include color-ripple('black');
}
