/*
  component: mat-option

  `<mat-option class=".nv-mat-option"></mat-option>`
*/
mat-option.nv-mat-option {
  color: var(--color-blue);
  .mdc-list-item__primary-text {
    color: var(--color-blue);
  }
  &.mdc-list-item {
    &.mdc-list-item--selected {
      background-color: unset;
    }
  }
}
