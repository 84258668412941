/************************************************
  RANGE SLIDER
************************************************/

/* usage
  component: ngx-slider

    <div class="nv-mat-range-slider">
        <form [formGroup]="rangeForm">
        <ngx-slider
            (userChangeEnd)="..."
            [options]="..."
            formControlName="..."
        ></ngx-slider>
        </form>
    </div>
*/

.nv-mat-range-slider .ngx-slider .ngx-slider-bar {
  background: var(--color-blue-light-6);
  height: 6px;
}

.nv-mat-range-slider .ngx-slider .ngx-slider-selection {
  background: var(--color-blue);
}

.nv-mat-range-slider .ngx-slider .ngx-slider-pointer {
  width: 12px;
  height: 12px;
  top: -3px;
  background-color: #fff;
  z-index: var(--z-idx-elevated);
  border-radius: 15px;
  box-shadow: 0.5px 0.5px 2px 1px rgba(0, 0, 0, 0.32);
  outline-width: 0;
}

.nv-mat-range-slider .ngx-slider .ngx-slider-pointer::before {
  content: '';
  position: absolute;
  top: 15px;
  left: 50%;
  border: 14px solid transparent;
  transform: translate(-50%, 0);
}

.nv-mat-range-slider .ngx-slider .ngx-slider-pointer:after {
  display: none;
}

.nv-mat-range-slider .ngx-slider .ngx-slider-bubble {
  bottom: -35px;
  background-color: var(--color-blue);
  color: #fff;
  border: 1px solid var(--color-blue);
  border-radius: 5px;
  text-align: center;
  padding: 2px 6px;
  white-space: nowrap;
  letter-spacing: 1px;
  font-weight: bold;
  font-size: var(--font-xxs);
}

.nv-mat-range-slider .ngx-slider .ngx-slider-bubble::before {
  content: '';
  position: absolute;
  top: -13px;
  left: 50%;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-bottom-color: inherit;
  transform: translate(-50%, 0);
}
