// v3 - Define the v3 color map, still keeping the v3 colors in case there are some places that still need them
$v3Colors: (
  'white': #ffffff,
  'black': #000000,
  'red': rgba(194, 0, 24, 1),
  'pink': rgba(255, 92, 143, 1),
  'salmon': rgba(255, 137, 137, 1),
  'yellow': rgba(255, 196, 0, 1),
  'green': rgba(0, 155, 72, 1),
  'teal': rgba(10, 196, 172, 1),
  'blue': rgba(10, 74, 206, 1),
  'navy': rgba(0, 1, 101, 1),
  'purple': rgba(102, 45, 145, 1),
);

// v4
$v4Colors: (
  // white group
  'white': #FFFFFF,

  // grey group
  'grey-dark-9': #09090B,
  'grey-dark-8': #18181B,
  'grey-dark-7': #27272A,
  'grey-dark-6': #3F3F46,
  'grey-dark-5': #52525B,
  'grey': #71717A,
  'grey-light-9': #A1A1AA,
  'grey-light-8': #D4D4D8,
  'grey-light-7': #E4E4E7,
  'grey-light-6': #F4F4F5,
  'grey-light-5': #FAFAFA,

  // blue group
  'blue-dark-9': #1D2758,
  'blue-dark-8': #2A3884,
  'blue-dark-7': #2E41A8,
  'blue-dark-6': #3850D2,
  'blue-dark-5': #3754EB,
  'blue': #4765FF,
  'blue-light-9': #7A8FFF,
  'blue-light-8': #A3B2FF,
  'blue-light-7': #BDC7FF,
  'blue-light-6': #E0E5FF,
  'blue-light-5': #F5F6FF,

  // red group
  'red-dark-2': #991B1B,
  'red-dark-1': #B91C1C,
  'red': #DC2626,
  'red-light-1': #FECACA,
  'red-light-2': #F6EDED,

  // green group
  'green-dark-2': #166534,
  'green-dark-1': #15803D,
  'green': #16A34A,
  'green-light-1': #BBF7D0,
  'green-light-2': #ECF5EF,

  // yellow group
  'yellow-dark-2': #775C08,
  'yellow-dark-1': #C49608,
  'yellow': #F2C436,
  'yellow-light-1': #F4E1A4,
  'yellow-light-2': #FDF9ED,

  // purple group
  'purple-dark-1': #8A5DB6,
  'purple-light-1': #F4EFF8,
);

$v4ColorCustomProperties: (
  'primary-text': 'grey-dark-8',
  'primary-text-disabled': 'grey-light-9',
  'standard-bar-blue': 'blue-light-9',
  'blue-text': 'blue',
  'blue-text-with-non-white-background': 'blue-dark-5',
  'app-default-bg': 'grey-light-5',
);

$v4VizColors: (
  'standard-bar-blue': #7A8FFF,
  'stacked-bar-seq-positive-5': #00494B,
  'stacked-bar-seq-positive-4': #076076,
  'stacked-bar-seq-positive-3': #076769,
  'stacked-bar-seq-positive-2': #3EA8A6,
  'stacked-bar-seq-positive-1': #BBE4D1,
  'stacked-bar-seq-neutral': #D4D4D8,
  'stacked-bar-seq-negative-1': #F0A882,
  'stacked-bar-seq-negative-2': #E47961,
  'stacked-bar-seq-negative-3': #C65154,
  'stacked-bar-seq-negative-4': #A52747,
  'stacked-bar-seq-negative-5': #81102D,
  'stacked-bar-cat-green': #2FB796,
  'stacked-bar-cat-blue': #516AEC,
  'stacked-bar-cat-yellow': #E7AD55,
  'stacked-bar-cat-purple': #8A5D86,
);

:root {
  // Legacy v3 colors
  @each $name, $color in $v3Colors {
    --color-#{$name}: #{$color};
    --color-#{$name}-90: #{mix($color, white, 90%)};
    --color-#{$name}-80: #{mix($color, white, 80%)};
    --color-#{$name}-70: #{mix($color, white, 70%)};
    --color-#{$name}-60: #{mix($color, white, 60%)};
    --color-#{$name}-50: #{mix($color, white, 50%)};
    --color-#{$name}-40: #{mix($color, white, 40%)};
    --color-#{$name}-30: #{mix($color, white, 30%)};
    --color-#{$name}-20: #{mix($color, white, 20%)};
    --color-#{$name}-10: #{mix($color, white, 10%)};
    --color-#{$name}-5: #{mix($color, white, 5%)};
    --color-#{$name}-3: #{mix($color, white, 3%)};
    --color-#{$name}-1: #{mix($color, white, 1%)};
    --color-#{$name}-dark: #{mix($color, black, 90%)};
    --color-#{$name}-dark-80: #{mix($color, black, 80%)};
    --color-#{$name}-dark-70: #{mix($color, black, 70%)};
    --color-#{$name}-dark-60: #{mix($color, black, 60%)};
    --color-#{$name}-dark-50: #{mix($color, black, 50%)};
  }
  // Legacy pre-v3 colors
  --color-blue-05: var(--color-blue-5);
  --color-blue-03: var(--color-blue-3);
  --color-blue-01: var(--color-blue-1);
  --color-red-pill: --var(--color-pink);

  // v4 colors
  @each $name, $colorHex in $v4Colors {
    --color-#{$name}: #{$colorHex};
  }

  // v4 color custom properties
  @each $name, $v4Color in $v4ColorCustomProperties {
    --color-#{$name}: var(--color-#{$v4Color});
  }

  // v4 viz colors
  @each $name, $colorHex in $v4VizColors {
    --color-#{$name}: #{$colorHex};
  }

  // opacity
  --color-opacity-none: 0;
  --color-opacity-10: 0.1;
  --color-opacity-30: 0.3;
  --color-opacity-50: 0.5;
}
