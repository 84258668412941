/*
  component: mat-checkbox

  `<mat-checkbox disableRipple class="nv-mat-checkbox"></mat-checkbox>`
*/

@use './../nv-typography/nv-typography.mixin.scss' as *;

/**
 *
 * currently only been used in - jchu:
 * flag-log-action-modal.component.html
 * school-tools-sync.component.html
 * cluster-user-modal-shell.component.html
 * cluster-user-portfolio.component.html
 *
 */
.nv-mat-checkbox {
  display: flex;
  margin: 0px;

  &.mat-mdc-checkbox,
  &.mat-mdc-checkbox.mat-accent {
    --mdc-checkbox-selected-focus-icon-color:  var(--color-blue);
    --mdc-checkbox-selected-hover-icon-color:  var(--color-blue);
    --mdc-checkbox-selected-icon-color:  var(--color-blue);
    --mdc-checkbox-selected-pressed-icon-color:  var(--color-blue);
    --mdc-checkbox-unselected-focus-icon-color:  var(--color-grey-dark-8);
    --mdc-checkbox-unselected-hover-icon-color:  var(--color-grey-dark-8);
    --mdc-checkbox-disabled-selected-icon-color: var(--color-blue-light-7);
    --mdc-checkbox-disabled-unselected-icon-color: var(--color-grey-light-9);
    --mdc-checkbox-unselected-icon-color: var(--color-grey-dark-8);
    --mdc-checkbox-unselected-pressed-icon-color: var(--color-grey-dark-8);
  }

  /**
    * Most of the styles here reset checkbox with class nv-mat-checkbox to what it looked prior to the angular material v15 upgrade
    *
    * Styles applied with the !important CSS rule are needed because of how high the specifity angular material CSS styles are applied with
  */
  .mdc-form-field {
    height: 16px;
    margin-left: 1px;
    .mdc-checkbox {
      cursor: default;
      margin: 0 -12px;
      padding-top: 9px;
      .mdc-checkbox__background,
      .mat-mdc-checkbox-touch-target {
        // reset to default width and height prior to angular material v15
        width: 16px;
        height: 16px;
        .mdc-checkbox__mixedmark {
					border-radius: 16px; // default border-radio of mixedmarl prior to angular material v15
          width: 8px;
				}
      }
      //default position of checkbox prior to angular material v15
      .mdc-checkbox__native-control {
        top: 11px;
        left: 11px;
        width: 16px;
        height: 16px;
        &:not(:disabled) {
          cursor: pointer;
          z-index: var(--z-idx-1);
        }
      }
    }

    label {
      @include nv-font-body-3;
      z-index: var(--z-idx-1);
      padding-left: var(--space-s);
    }
  }

  // disables ripple in all checkbox states; checked, unchecked, indetermine, disabled
  &.mat-mdc-checkbox,
  &.mat-mdc-checkbox.mat-accent {
    .mdc-checkbox__native-control~.mdc-checkbox__ripple{
      background: unset;
      opacity: 0;
      display: none;
    }
    .mdc-checkbox:hover .mdc-checkbox__native-control~.mdc-checkbox__ripple {
      opacity: 0;
    }
  }
  &.mdc-checkbox--disabled {
    pointer-events: unset;
    .mdc-form-field {
      .mdc-checkbox {
        &:hover {
          cursor: not-allowed;
        }
      }
      label {
        color: var(--color-primary-text-disabled);
      }
    }
  }
}

/*
 * used with .nv-mat-checkbox to override default square checkbox style to a circle (AB)
 * Currently being used in:
 * fixed-table.component.html
*/
.nv-circle-mat-checkbox {
  // base, unchecked layer of checkbox
  .mdc-form-field {
    .mdc-checkbox {
      .mdc-checkbox__background,
      .mat-mdc-checkbox-touch-target {
        border-color: var(--color-blue-20) !important;
        border-radius: 70%;
        border-width: thin;
      }
    }
  }

 // when checkbox enabled and checked
  &.mat-checkbox-checked .mat-checkbox-background {
    background-color: var(--color-blue-05);
  }

  &.mat-mdc-checkbox-checked {
    .mdc-form-field {
      .mdc-checkbox {
        .mdc-checkbox__background,
        .mat-mdc-checkbox-touch-target {
          background-color: var(--color-blue-05) !important;
          border-color: var(--color-blue) !important;
          scale: 1.1;
          svg {
            filter: brightness(0) saturate(100%) invert(23%) sepia(88%) saturate(2097%) hue-rotate(206deg) brightness(92%) contrast(96%);
            scale: 0.75;
          }
        }
      }
    }
  }

  &.mat-mdc-checkbox-checked {
    &.mdc-checkbox--disabled {
      .mdc-form-field {
        .mdc-checkbox {
          .mdc-checkbox__background,
          .mat-mdc-checkbox-touch-target {
            background-color: var(--color-blue-30) !important;
            border-color: var(--color-blue) !important;
          }
        }
      }
    }
  }
}

// !!IMPORTANT - .nv-checkbox-wrapper & %nv-checkbox is deprecated, please use .nv-mat-checkbox
/**
 *
 * currently been used in main page and student profile page - jchu:
 * list-display-milestone.component.html
 * list-display-career-path.component.html
 * list-display-college-path.component.html
 * list-display-student.component.html
 *
 */
.nv-checkbox-wrapper {
  @extend %nv-checkbox;
}

// This will move to a separate component eventually

%nv-checkbox {
  padding: 0;
  justify-content: center;
  margin-left: var(--space-xs);

  .checkbox {
    height: var(--space-l);
    width: var(--space-l);
    display: inline-block;
    position: relative;
    border-radius: var(--space-xxs);
    background-image: url(/assets/v3/img/checkbox-off.png);
    background-position: center;
    background-size: contain;
    vertical-align: middle;

    &.checkbox-selected {
      background-image: url(/assets/v3/img/checkbox-on.png);
    }
  }
}
