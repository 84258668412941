@import './variables.scss';
@import './components.scss';
@import './custom-material.scss';
@import './custom-theme.scss';
@import './nvMaterial.scss';
@import './scrollbar.scss';

html {
  color: var(--color-primary-text);
  // box-sizing: border-box;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: var(--font-family-normal);
}

* code {
  font-family: var(--font-family-mono);
}

.unformatted-link {
  color: inherit; 
  text-decoration: inherit;
  height: 100%;
  width: 100%;
}
