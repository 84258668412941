/* 
  Common font weight name mapping
  https://developer.mozilla.org/en-US/docs/Web/CSS/font-weight#common_weight_name_mapping
  100 - Thin
  200 - Extra Light
  300 - Light
  400 - Normal (Regular)
  500 - Medium
  600 - Semi Bold (Demi Bold)
  700 - Bold
  800 - Extra Bold (Ultra Bold)
  900 - Black (Heavy)
  950 - Extra Black (Ultra Black)
*/

@import url('https://fonts.googleapis.com/css?family=Lato:100,100i,300,300i,400,400i,700,700i,900,900i&display=swap&subset=latin-ext');

/* 
  Google fonts api is missing some Lato variants, so we have to download them manually
  We selectively and manually install these pre-downloaded variants provided by Adobe typekit:
    - 500 / medium
    - 600 / semibold

  Downloaded from: https://fonts.adobe.com/fonts/lato
*/

// Load downloaded Lato font with weight: 500 / medium / normal
/* Webfont: Lato-Medium */@font-face {
  font-family: 'NvLatoMedium';
  src: url('../../assets/fontAssets/Lato/fonts/Lato-Medium.eot'); /* IE9 Compat Modes */
  src: url('../../assets/fontAssets/Lato/fonts/Lato-Medium.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../assets/fontAssets/Lato/fonts/Lato-Medium.woff2') format('woff2'), /* Modern Browsers */
       url('../../assets/fontAssets/Lato/fonts/Lato-Medium.woff') format('woff'), /* Modern Browsers */
       url('../../assets/fontAssets/Lato/fonts/Lato-Medium.ttf') format('truetype');
  font-style: normal;
  font-weight: 500;
  text-rendering: optimizeLegibility;
}

// Load downloaded Lato font with weight: 500 / medium / italic
/* Webfont: Lato-MediumItalic */@font-face {
  font-family: 'NvLatoMediumItalic';
  src: url('../../assets/fontAssets/Lato/fonts/Lato-MediumItalic.eot'); /* IE9 Compat Modes */
  src: url('../../assets/fontAssets/Lato/fonts/Lato-MediumItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../assets/fontAssets/Lato/fonts/Lato-MediumItalic.woff2') format('woff2'), /* Modern Browsers */
       url('../../assets/fontAssets/Lato/fonts/Lato-MediumItalic.woff') format('woff'), /* Modern Browsers */
       url('../../assets/fontAssets/Lato/fonts/Lato-MediumItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: 500;
  text-rendering: optimizeLegibility;
}

// Load downloaded Lato font with weight: 600 / semibold / normal
/* Webfont: Lato-Semibold */@font-face {
  font-family: 'NvLatoSemibold';
  src: url('../../assets/fontAssets/Lato/fonts/Lato-Semibold.eot'); /* IE9 Compat Modes */
  src: url('../../assets/fontAssets/Lato/fonts/Lato-Semibold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../assets/fontAssets/Lato/fonts/Lato-Semibold.woff2') format('woff2'), /* Modern Browsers */
       url('../../assets/fontAssets/Lato/fonts/Lato-Semibold.woff') format('woff'), /* Modern Browsers */
       url('../../assets/fontAssets/Lato/fonts/Lato-Semibold.ttf') format('truetype');
  font-style: normal;
  font-weight: 600;
  text-rendering: optimizeLegibility;
}

// Load downloaded Lato font with weight: 600 / semibold / italic
/* Webfont: Lato-SemiboldItalic */@font-face {
  font-family: 'NvLatoSemiboldItalic';
  src: url('../../assets/fontAssets/Lato/fonts/Lato-SemiboldItalic.eot'); /* IE9 Compat Modes */
  src: url('../../assets/fontAssets/Lato/fonts/Lato-SemiboldItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../assets/fontAssets/Lato/fonts/Lato-SemiboldItalic.woff2') format('woff2'), /* Modern Browsers */
       url('../../assets/fontAssets/Lato/fonts/Lato-SemiboldItalic.woff') format('woff'), /* Modern Browsers */
       url('../../assets/fontAssets/Lato/fonts/Lato-SemiboldItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: 600;
  text-rendering: optimizeLegibility;
}

:root {
  --font-xxxs: 10px; // caption-1, caption-2
  --font-xxs: 12px; // body-3, subtitle-3
  --font-xs: 14px; // body-2, subtitle-2
  --font-s: 16px; // body-1, subtitle-1
  --font-m: 18px;
  --font-l: 20px; // header-2
  --font-xl: 22px;
  --font-xxl: 24px; // header-1
  --font-xxxl: 32px; // display
  --font-4xl: 40px;

  --font-family-normal: 'Lato', --apple-system, sans-serif;
  --font-family-mono: 'Lato', Courrier, monospace; // lato font does not have a mono variant, so use the same font as normal

  --font-weight-normal: 400;
  --font-weight-bold: 700;
  --font-weight-bolder: 900;
}
