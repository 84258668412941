/**
  * Reset font size and font color of mat-menu buttons to default prior to material v15
  * This applies when a button with the mat-menu-item directive is used. eg
  * <mat-menu>
  *   <button mat-menu-item>
  *   </buttton>
  * </mat-menu>
*/
button {
  // selects angular material buttons(mat-button, mat-raised-button, etc)
  &.mat-mdc-menu-item.mdc-list-item {
    // selects angular material menu buttons(mat-menu-item, etc)
    .mdc-list-item__primary-text, .mat-mdc-menu-item-text {
      font-size: 14px;
      color: rgba(0,0,0,0.87);
    }

  }
}