@use './../nv-typography/nv-typography.mixin.scss' as *;

/*
  component: mat-calendar

  <mat-datepicker></<mat-datepicker>
*/
// these styles wrap the mat-datepicker to match the color schemes and spacing of the portal (JE)
mat-datepicker-content.mat-datepicker-content mat-calendar.mat-calendar {
  height: auto;

  .mat-calendar-content {
    // background-color: orange;
    padding: 0;

    .mat-calendar-table {

      .mat-calendar-table-header {
        background-color: var(--color-blue-light-5);
        th {
          padding: 4px 0;
          @include nv-font-caption-2(blue); // typography
        }
        :nth-child(1) {
          height: var(--space-xl);
        }
        .mat-calendar-table-header-divider {
          display: none;
        }
      }

      tbody.mat-calendar-body {
        tr[aria-hidden='true'] {
          display: none; // hide empty rows
        }
        .mat-calendar-body-label {
          opacity: 0; // hide the month label inside the calendar
        }

        .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover,
        .cdk-keyboard-focused .mat-calendar-body-active,
        .cdk-program-focused .mat-calendar-body-active {
          & > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
            background-color: var(--color-blue-03);
          }
        }

        .mat-calendar-body-cell .mat-calendar-body-cell-content {
          @include nv-font-body-2(blue); // typography
        }

        .mat-calendar-body-disabled {
          .mat-calendar-body-cell-content {
            color: var(--color-blue);
            opacity: var(--color-opacity-50);
          }
          .mat-calendar-body-cell-content:hover {
            background-color: inherit;
          }
        }
        .mat-calendar-body-cell .mat-calendar-body-selected {
          color: white;
          background-color: var(--color-blue);
        }

        .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected) {
          border-color: var(--color-blue);
          opacity: var(--color-opacity-50);
        }

        .mat-calendar-body-today:not(.mat-calendar-body-selected) {
          border-color: var(--color-blue);
        }

        color: var(--color-blue);
      }
    }
  }
}
