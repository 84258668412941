// TODO
// MOVE THESE TO SEPARATE FILES - athompson

// These mat-form-field selector resets the mat-form-field component to what it looked like for the most part prior to angular material v15
mat-form-field {
  .mdc-text-field--filled {
    background-color: transparent !important;
  }
  .mat-mdc-form-field-focus-overlay {
    background-color: transparent;
  }
  .mat-mdc-text-field-wrapper.mdc-text-field {
    padding: 0;
  }
}


%nv-mat-form-field {
  width: 100%;

  .mdc-line-ripple {
    display: none;
  }

  .mat-form-field-label {
    color: var(--color-blue-50);
  }

  &.mat-form-field-invalid,
  &.mat-focused {
    .mat-mdc-input-element {
      caret-color: var(--color-blue);
    }

    .mat-form-field-label {
      color: var(--color-blue-50);
    }
  }
}
