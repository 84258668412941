// Make use of native CSS custom properties to
// define variables instead of SASS variables ($), if possible.
// One reason being, CSS custom properties can be targeted by javascript.
// Custom variables are fully supported by Chrome - JYR

// if variables are definitely scoped to a particular component or class,
// move them from the imported files to a css class for that component/class
@import './variables/variable-fonts.scss';
@import './variables/variable-layout-sizes.scss';
@import './variables/variable-colors.scss';
@import './../../../styles/variables/screen-size-breakpoints.scss';

:root {
  --app-background-image: url(https://nv-portal-landing-1.firebaseapp.com/assets/new-login-bg.svg);
}